/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useRef, useState } from 'react'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { api2 } from 'services/api'
import { Order } from 'types'

type Params = {
    onSuccess?: () => void
}

const STATUS = {
    '0': 'canceled',
    '1': 'captured',
    '3': 'in_production',
    '4': 'to_collect',
    '5': 'collected',
    '6': 'in_progress',
    '7': 'at_client',
    '8': 'delivered',
    '9': 'returned',
    '10': 'failed',
    '11': 'hand_delivered',
}

function useModalDeliveredCodeCTL({ onSuccess }: Params) {
    const modalShowRef = useRef<boolean>()

    const { setErrorModal } = useUI()

    const [order, setOrder] = useState<Order>()
    const [code, setCode] = useState(undefined)

    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)

    const _setShow = useCallback(async ({ order }: { order: Order }) => {
        setShow(true)
        setOrder(order)
    }, [])

    const _onClose = useCallback(() => {
        setShow(false)
        setOrder(undefined)
        setCode(undefined)
    }, [])

    const _setCode = useCallback((value: any) => {
        setCode(value.target.value)
    }, [])

    const _updateStatus = useCallback(async () => {
        setLoading(true)
        try {
            await api2.post(
                `/orders/${order.id}/delivered`,
                {
                    original_status: STATUS[order.status],
                    reason: {
                        code: 'ifood-error',
                        description: 'Problema com o código Ifood',
                    },
                    receiver: {
                        code,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            if (onSuccess) {
                onSuccess()
            }
            _onClose()
        } catch (error) {
            setErrorModal({
                title: 'Erro',
                subtitle: showErrors(error),
            })
        }
        setLoading(false)
    }, [_onClose, code, onSuccess, order, setErrorModal])

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setVisible(false)
            setCode(undefined)
            setOrder(undefined)
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    return {
        loading,
        visible,
        show,
        order,
        code,
        _setCode,
        _updateStatus,
        _setShow,
        _onClose,
    }
}

export { useModalDeliveredCodeCTL }
