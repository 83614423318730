import { forwardRef, memo, useImperativeHandle } from 'react'

import { InputItem } from 'components/_common'
import OrderSummary from 'components/_store-general-vision/order-summary'
import { DefaultButton } from 'components/default-button/default-button'
import ModalLoading from 'components/modal-loading'

import { Order } from 'types'

import { useModalDeliveredCodeCTL } from './modal-delivered-code.controller'
import { Box, Container, CloseIcon, Header, Title, Content, ContainerButton } from './modal-delivered-code.styled'

type Params = {
    order: Order
}

export type ModalDeliveredCodeRef = {
    show?(params: Params): void
}

interface ModalDeliveredCodeProps {
    onSuccess?(): void
}
const ModalDeliveredCode = memo(
    forwardRef<ModalDeliveredCodeRef, ModalDeliveredCodeProps>(({ onSuccess }, ref) => {
        const { loading, visible, show, order, code, _setCode, _updateStatus, _setShow, _onClose } =
            useModalDeliveredCodeCTL({ onSuccess })

        useImperativeHandle(
            ref,
            () => ({
                show: _setShow,
            }),
            [_setShow]
        )

        if (!visible) {
            return null
        }

        return (
            <Container visible={visible}>
                <Box show={show}>
                    <Header>
                        <Title>Confirmar Entrega</Title>
                        <CloseIcon icon="times" onClick={_onClose} />
                    </Header>
                    <Content>
                        {order && <OrderSummary order={order as any} />}
                        <InputItem
                            inputProps={{
                                placeholder: 'Código de Entrega',
                                onChange: _setCode,
                                value: code,
                            }}
                        />
                        <ModalLoading visible={loading} />
                        <ContainerButton>
                            <DefaultButton onClick={_onClose} title="Cancelar" variant="danger" />
                            <DefaultButton onClick={_updateStatus} title="Confirmar" variant="success" />
                        </ContainerButton>
                    </Content>
                </Box>
            </Container>
        )
    })
)

export { ModalDeliveredCode }
