import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

const Container = styled.div<{ visible?: boolean }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0, 0.25);
    animation-name: show;
    animation-duration: 200ms;
`

const Box = styled.div<{ show?: boolean }>`
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 300px;
    max-width: 95%;
    max-height: 95%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: -1px 3px 14px 0px rgba(0, 0, 0, 0.3);

    transition: all 200ms ease-out;

    .title {
        font-weight: 600;
        user-select: none;
    }
    .icon {
        cursor: pointer;
        user-select: none;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 20px;
    }

    ${({ show }) =>
        show
            ? css`
                  animation-name: show;
                  animation-duration: 400ms;
              `
            : css`
                  animation-name: close;
                  animation-duration: 450ms;
              `}
`

const Content = styled.div`
    flex: 1;
    overflow: hidden;
    position: relative;
    flex-direction: column;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .input-item {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px !important;
    }
`

const Header = styled.div`
    display: flex;
    padding: 8px;
    background-color: #f1f1f1;
    position: relative;
`

const Title = styled.div`
    text-align: center;
    flex: 1;
`

const CloseIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
    position: absolute;
    right: 10px;
    color: #8e8e8e;
    &:hover {
        color: ${({ theme }) => theme.colors.errorMessage};
    }
`

const ContainerButton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
`

export { Box, Container, CloseIcon, Header, Title, Content, ContainerButton }
